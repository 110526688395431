import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

const LOGIN_PATH = '/login';
const NO_ACCESS_PATH = '/no-access';

export default defineNuxtRouteMiddleware(async () => {
  const { $supabase } = useNuxtApp();
  const user = await $supabase.auth.getUser();

  if (user.error) {
    return navigateTo(LOGIN_PATH);
  }

  const userAcccess = await $supabase.rpc('get_user_access', {
    user_id: user.data.user.id
  });

  if (userAcccess.data?.length === 0) {
    return navigateTo(NO_ACCESS_PATH);
  }
});

